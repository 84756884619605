import React from 'react';
import { ImShield } from "react-icons/im";
import { TbFingerprintScan } from "react-icons/tb";
import { VscRepoPull } from "react-icons/vsc";
import { TfiWrite } from "react-icons/tfi";
import { FaLaptopCode } from "react-icons/fa6";
import { TbClockCog } from "react-icons/tb";
import { FaPeopleLine } from "react-icons/fa6";
import { TbCloudLock } from "react-icons/tb";
import { BsPersonWorkspace } from "react-icons/bs";

import { FaUserTie} from 'react-icons/fa';
import OffDec from '../assest/offdec.png'
import OffDec1 from '../assest/offdec1.png'





// ... import other icon components as needed

const serviceData = [
  { name: 'IAM SME and Implementation Services', icon: <VscRepoPull size={70} color="#656565" /> },
  { name: 'IAM 24/7 Managed Services', icon: <TbClockCog  size={70} color="#656565" /> },
  { name: 'IAM Staffing Services', icon: <FaPeopleLine  size={70} color="#656565" /> },
  { name: 'Cloud Security', icon: <TbCloudLock  size={70} color="#656565" /> },
  { name: 'Cyber Security Internship', icon: <BsPersonWorkspace  size={70} color="#656565" /> },
  { name: 'PAM SME and Implementation Services', icon: <FaUserTie size={70} color="#656565" /> },
 
];

const ServiceCard = ({ name, icon, isBottomItem }) => (
  <div className="offering-card" style={{ color: isBottomItem ? "#656565" : "#656565" }}>
    <div className="offering-icon">{icon}</div>
    <div className="offering-name">{name}</div>
  </div>
);

const Services = () => {
  return (
    <div className="offerings-container">
    <div className="offerings-content">

    <div className="services-header-img">
    <img src={OffDec1} alt="Services Header"  className="services-header-image" /> {/* Del is already imported as an image */}
    
  </div> 
        <div className="offerings-grid">
      {serviceData.map((service, index) => (
        <ServiceCard
          key={index}
          name={service.name}
          icon={service.icon}
          isBottomItem={index >= serviceData.length - 4}
        />
      ))}
      </div>
      </div>
    </div>
  );
};

export default Services;
