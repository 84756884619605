import React, { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import logo from '../assest/Logo1.png'; 
import logo1 from '../assest/small cobine logo.png'; 

import './comp.css'; 

const NavigationBar = () => {
  
  const [clicked, setClicked] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (location.hash) {
      const section = document.getElementById(location.hash.replace('#', ''));
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const handleNavLinkClick = (path) => {
    if (path === 'company') {
      // For the company link, navigate to the new route
      navigate('/company');
    } else {
      // For other links, scroll to the section
      const section = document.getElementById(path);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const isActive = (path) => {
    // Check if the current path is active for styling purposes
    return location.pathname === path || (location.pathname === '/' && path === 'home');
  };

 

  const handleClick = () => {
    // Toggle the clicked state
    setClicked(!clicked);
  };




  return (
    <div className={`navbar-container ${scrolled ? 'scrolled' : ''}`}>
      <nav className="navbar" id="nAv">
        <Link to="/" className="logo-container">
        <img src={logo} alt="Large Logo" className="logo small-logo"/>
        <img src={logo1} alt="Small Logo" className="logo large-logo"/>        </Link>
        <ul id="navTabs" className={`nav-links ${clicked ? "active" : ""}`}>
          <li>
            <Link to="/#home" onClick={() => handleNavLinkClick('home')} className={isActive('/') ? 'nav-link active' : 'nav-link'}>
              Home
            </Link>
          </li>
          {/* ... other list items ... */}
          <li>
            <Link to="/#offerings" onClick={() => handleNavLinkClick('offerings')} className={isActive('/#offerings') ? 'nav-link active' : 'nav-link'}>
              Services
            </Link>
          </li>
          {/* ... */}
          <li>
            <Link to="/#services" onClick={() => handleNavLinkClick('services')} className={isActive('/#services') ? 'nav-link active' : 'nav-link'}>
              Offerings
            </Link>
          </li>
          <li>
          <Link to="/#partners" onClick={() => handleNavLinkClick('partners')} className={isActive('/#partners') ? 'nav-link active' : 'nav-link'}>
          Partners
          </Link>
        </li>
          <li>
            <Link to="/company" onClick={() => handleNavLinkClick('company')} className={isActive('/company') ? 'nav-link active' : 'nav-link'}>
              Company
            </Link>
          </li>
          <li>
          <Link to="/contact" onClick={() => handleNavLinkClick('contact')} className={isActive('/contact') ? 'nav-link active' : 'nav-link'}>
            Contact
          </Link>
        </li>
        </ul>
       
        <div id='mobile' onClick={handleClick}>
          <i id='ba' className={clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
        </div>

      </nav>
    </div>
  );
};

export default NavigationBar;