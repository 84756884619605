import React from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';
import './comp.css'; // Assume you have a Footer.css file for styling

const Footer = () => {
  const navigate = useNavigate();

  const handleNavLinkClick = (path) => {
    if (path === 'company') {
      // For the company link, navigate to the new route
      navigate('/company');
    } else {
      // For other links, scroll to the section
      const section = document.getElementById(path);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };
  return (
    <footer className="footer">
      <Container>
        {/* Add "justify-content-center" to center the columns */}
        <Row className="justify-content-center">
          <Col md={3}>
            <h5>COMPANY</h5>
            <ul>
              <li >
              
              <Link to="/company" onClick={() => handleNavLinkClick('company')} style={{color: 'whitesmoke'}}>
              About Us
            </Link>
              </li>
              <li>
               
              <Link to="/contact" onClick={() => handleNavLinkClick('contact')} style={{color: 'whitesmoke'}}>
              Contact Us
                          </Link>
              
              </li>
            </ul>
          </Col>
          <Col md={3}>
            <h5>CORPORATE OFFICE</h5>
            <ul className="contact-details">
              <li>800 N King Street Suite 304 1284 Wilmington, DE 19801</li>
              <li>contact@vivere-global.com</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-3">
            © Vivere Global LLC 2024. All Rights Reserved
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
