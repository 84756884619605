import React from 'react';
import './comp.css';
import BussIng from '../assest/businessIng.png'
import CareerBanner from '../assest/interships.jpeg';


const BusinessInquiries = () => {

  return (
    <div className="mainContainer">


    <img src={CareerBanner} alt="Career Banner" style={{width: "750px", display: "block", marginLeft: "auto", marginRight: "auto"}}/>

    <div className="careersContainer">
    
    <img src={BussIng} alt="Services Header"  className="buss-header-image" /> {/* Del is already imported as an image */}
      <p>Interested in partnering with us or have a business proposal? Please Contact Us.</p>
      <div className="job bus-con">
        <h3>Contact Details</h3>
        <p><strong>Email: </strong>contact@vivere-global.com </p>
        <p><strong>Address:</strong> 800 N King Street Suite 304 1284 Wilmington, DE 19801</p>
      </div>
    </div>
    </div>

  );
};

export default BusinessInquiries;
