import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import NavBar from './components/NavBar'
import HeroSection from './components/HeroSection';
import Offerings from './components/Offerings';
import Footer from './components/Footer';
import Partners from './components/Partners'
import Services from './components/Services';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css';
import Company from './components/Company'
import Career from './components/Careers'
import BusinessInquiries from './components/BusinessInquiries';
import Contact from './components/ContactUs'

import './App.css';
import './components/comp.css';


const App = () => {
  return (
    <Router>
    <NavBar />
    <Routes>
      <Route path="/" element={
        <>
        <div style={{paddingTop: 160}}> </div>
          <HeroSection />
          
          <div id="offerings"></div>
          <Offerings  />
       
          <div id="services" className='services' ></div>
          <Services />


          <div id="partners"> </div>
          <Partners />
          
          <Footer />
        </>
      } />
      <Route path="/company" element={<Company />} />
      <Route path="/contact" element={<Contact />} />

    </Routes>
  </Router>
);
}

export default App
