import React from 'react';
import './comp.css';
import defence from "../assest/Defence.png"
import client from "../assest/Client.jpg"
import partner from "../assest/business.jpeg"

import CoreFocus from '../assest/coureFocus.png'
import ChooseUs from '../assest/chooseus.png'
import ChooseValues from '../assest/corevalues.png'

const AboutUs = () => {
  return (
    <div className="aboutUsContainer">
      <section className="aboutUsHeader">
        <h1>Securing Your Digital Frontier</h1>
        <p>Vivere fuses state-of-the-art technology with steadfast security practices, providing robust protection for businesses around the globe. Our approach is grounded in the latest advancements, delivering reliable cyber defense solutions that businesses can trust. We're committed to maintaining the integrity of your digital operations, ensuring a secure and resilient online presence.</p>
        </section>

      <section className="aboutUsCoreFocus">
        <img src={CoreFocus} alt="Services Header"  className="corefocus-header-image" /> {/* Del is already imported as an image */}

        <div className="focusAreas">
          <div className="focusItem">
            <h3>Innovative Defense</h3>
            <p>We pioneer advanced solutions to stay ahead of cyber threats, ensuring your business remains unassailable.</p>
          </div>
          <div className="focusItem">
            <h3>Empowering Education</h3>
            <p>Equipping your team with the knowledge to identify and counteract potential security risks.</p>
          </div>
          <div className="focusItem">
            <h3>Trusted Partnerships</h3>
            <p>Collaborating closely with our clients, we forge relationships based on trust and mutual growth.</p>
          </div>
        </div>
      </section>

      <section className="aboutUsWhy">
        <img src={partner} alt="Why Choose Us" className="whyImage" />
        <img src={ChooseUs} alt="Services Header"  className="corefocus-header-image" /> {/* Del is already imported as an image */}
        <p>Our commitment to excellence and our proactive approach set us apart, ensuring that your data and digital assets are always secure.</p>
      </section>

      <section className="aboutUsValues">
      <img src={ChooseValues} alt="Services Header"  className="corevalue-header-image" /> {/* Del is already imported as an image */}
      <ul className="valuesList">
          <li>Integrity in every action</li>
          <li>Dedication to client success</li>
          <li>Innovative spirit and continual growth</li>
        </ul>
      </section>

    </div>
  );
};

export default AboutUs;
