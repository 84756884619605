import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import './comp.css';
import p1 from '../assest/microsoft.png'
import p2 from '../assest/Tential.webp'
import p3 from '../assest/lenora.webp'
import p4 from '../assest/synergy.png'

import PartDec from '../assest/partdec.png'

const PartnersSection = () => {
  const partners = [
    { id: 1, src: p1, alt: 'Partner 1' },
    { id: 2, src: p2, alt: 'Partner 2' },
    { id: 3, src: p3, alt: 'Partner 3' },
    { id: 4, src: p4, alt: 'Partner 4' },
   

  ];


  return (
 <div >
    <Container className="my-5 ">
      <Row>
        <Col className="text-center mb-4">
        <img src={PartDec} alt="Services Header"  className="services-headerPart-image" style={{paddingTop: 20}}/> {/* Del is already imported as an image */}
        </Col>
      </Row>
      <Row className="justify-content-center">
      {partners.map((partner) => (
        <Col xs={6} md={4} lg={2} key={partner.id} className="partner-col mb-3 px-2">
          <Image src={partner.src} alt={partner.alt} className="partner-image" />
        </Col>
      ))}
    </Row>
    </Container>
    </div>
  );
};

export default PartnersSection;
