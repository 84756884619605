import React from 'react';
import Footer from './Footer';
import AboutUs from './AboutUs';
import './comp.css';

const CareerBanner = () => {
  return (

    <div style={{paddingTop: "250px"}}>
    <AboutUs/>
    <Footer/>
    </div>
  );
};

export default CareerBanner;
