import React from 'react';
import './comp.css';
import JoinTeam from '../assest/joinTeam.png'

const Careers = () => {
  const email = 'contact@vivere-global.com';
  const subject = encodeURIComponent('Application for Career Opportunities');
  const body = encodeURIComponent('Please attach your resume and any other relevant documents.');

  const mailtoHref = `mailto:${email}?subject=${subject}&body=${body}`;
  return (
    <div className="mainContainer">
    <div className="careersContainer" >
    <img src={JoinTeam} alt="Services Header"  className="corefocus-header-image" /> {/* Del is already imported as an image */}
      
      <p>Looking to make a difference in cybersecurity? Explore opportunities with us.</p>
      <div className="jobListings">        
        
        <div className="job">
          <h3>Internship</h3>
          <p>Send out your resume and information over here</p>
          <button ><a href={mailtoHref} target="_blank" rel="noopener noreferrer" style={{fontSize:'19px', color: 'white'}}>Apply Now Email</a></button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Careers;
