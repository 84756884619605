import React, { useState, useEffect}  from 'react';
import { Carousel, Container, Button, Img } from 'react-bootstrap';
import './comp.css'

// Assume you have images imported or defined somewhere
import image1 from '../assest/car1.jpeg';
import image2 from '../assest/car2.jpeg';
import image3 from '../assest/car3.jpeg';
import image4 from '../assest/car4.jpeg'
import image5 from '../assest/car5.jpeg'
import image6 from '../assest/globe.png'

import logoName from '../assest/Vivere Globallarge.png'

const HeroSection = () => {
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const loadImage = image => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image;
        loadImg.onload = () => 
          resolve(image.url);
        loadImg.onerror = err => reject(err);
      });
    };
    
    Promise.all([
      loadImage(image1),
      loadImage(image2),
      loadImage(image3),
      loadImage(image4),
      loadImage(image5),
      loadImage(image6),
    ]).then(() => setImagesLoaded(true))
      .catch(err => console.log("Failed to load images", err));
  }, []);

  return (
    <Container id='heroImg' fluid className="text-center hero-container">
  <div className="hero-box">
    <div className="hero-text">
      <h1 className="jumbotron-heading">
        Welcome to <img src={logoName} alt="Vivere Global Logo" className='jumboImage'/>
      </h1>
      <p className="lead" >Safeguarding Your Digital Ecosystem with Next-Generation Identity Authentication and Access Management.</p>
    </div>
  </div>
</Container>
  );
};

export default HeroSection;
