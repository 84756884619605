import React from 'react'
import { TabPane, Tab } from 'semantic-ui-react'
import { GiIdCard } from "react-icons/gi";
import { RiShieldUserFill } from "react-icons/ri";
import { TiCloudStorage } from "react-icons/ti";
import { BsBuildingFillGear } from "react-icons/bs";
import { GiProgression } from "react-icons/gi";
import { FaRegCompass } from "react-icons/fa";
import { GrCompliance } from "react-icons/gr";
import { RiFileList3Line } from "react-icons/ri";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { FiMonitor } from "react-icons/fi";
import { FaLaptopFile } from "react-icons/fa6";
import Del from '../assest/del.webp'; // Update with your actual image path
import Cyb from "../assest/Cyber.png"
import Del1 from "../assest/del(1).png"
import ServDec from '../assest/servdec.png'
import ServDec1 from '../assest/servdec1.png'


import { AiOutlineTool } from "react-icons/ai";
import { TbArrowsRandom } from "react-icons/tb";
import { BsTools } from "react-icons/bs";
import { LuMonitorCheck } from "react-icons/lu";
import { GrDocumentPerformance } from "react-icons/gr";
import { AiOutlineAudit } from "react-icons/ai";
import { GrDocumentUser } from "react-icons/gr";





const panes = [
  { menuItem: {content: 'Identity Governance Access',
      className: 'tab-name' 

}, 
  render: () => (
    <TabPane>
      <div className="specializations-section " >
        <div className="specialization">
          <div className="icon"> <RiShieldUserFill size="3em" color='#656565'/>
          </div>
          <h3 className='IGAHeader'>IdentityNow</h3>
            <p style={{fontSize: "15px", color: "black" }}>
            Implementing IdentityNow provides streamlined cloud-based access control, automating user provisioning and de-provisioning, ensuring compliance, and delivering real-time analytics for decision-making.
            </p>
        </div>
        <div className="specialization">
          <div className="icon"><i className="some-icon-class" /> <GiIdCard size="3em" color='#656565' /> </div>
          <h3 className='IGAHeader'>IdentityIQ</h3>
          <p style={{fontSize: "15px", color: "black" }}>

          IdentityIQ optimizes identity governance, ensuring user access visibility and effective permission management for operational integrity, risk mitigation, compliance, and enhanced security posture.          </p>
        </div>
        <div className="specialization">
          <div className="icon"><i className="some-icon-class" /> <TiCloudStorage size="3em" color='#656565' />
          </div>
          <h3 className='IGAHeader'>Saviynt</h3>
          <p style={{fontSize: "14.5px", color: "black" }}>
          
Saviynt offers cloud-based identity governance solutions for comprehensive security. 
It seamlessly integrates with your IT setup, providing intelligent access controls, risk assessments, and compliance monitoring to enhance your security framework.
          </p>
        </div>
       
       
      </div>
    </TabPane>
  )
},
  { menuItem: 
  {content: 'Privilaged Access Management',
      className: 'tab-name' 

}
  ,  render: () => (
    <TabPane>
  <div className="content-container content-box">
    <div className='leftImg'> 
    <img src={Cyb} alt="Cyberark" />
    </div>
    <div className="text-section">
    <h3>CyberArk</h3>
    <p>
      The CyberArk implementation by our company will focus on protecting against threats related to privileged accounts. It will involve integrating CyberArk's solutions to manage privileges, rotate credentials, and control application access, thereby minimizing the attack surface and enhancing overall security posture.
    </p>
     
    </div>
  </div>

  <div className="content-container content-box">
    <div className="text-section">
    <h3>Delinea</h3>
    <p>
      Our company's implementation of Delinea will establish a secure environment for privileged accounts by managing and auditing account activities. The deployment will involve setting up robust access controls, monitoring sessions, and recording critical system changes to ensure security compliance throughout the privileged account lifecycle.
    </p>
    </div>
    <div className='rightImg'> 
    <img src={Del1} alt="Delinea" />

    </div>
  </div>
</TabPane>


  )},
  { menuItem: 
  {content: 'Management Services Organizations',
  className: 'tab-name ' 

}, render: () => 
  <TabPane>
    <div className="services-section-header">
      <h3>We deliver comprehensive Management Services tailored to your organizational needs. 
      Our commitment is to provide exceptional support and strategic solutions to enhance your 
      operational efficiency.</h3>
    </div>
    <div className="services-list">
      <div className="service-item">
        <GiProgression size="5em" color="#656565" />
        <div>
          <h4 className="services-section-subheader" >Process Optimization</h4>
          <p className='services-section-point' >Streamlining workflows to boost productivity and performance.</p>
        </div>
      </div>
      <div className="service-item">
        <GrCompliance size="5em" color="#656565" />
        <div>
          <h4 className="services-section-subheader" >Regulatory Compliance</h4>
          <p className='services-section-point' >Compliance with industry regulations and standards.</p>
        </div>
      </div>
      <div className="service-item">
        <MdFormatListBulletedAdd size="5em" color="#656565" />
        <div>
          <h4 className="services-section-subheader" >Customized Reporting</h4>
          <p className='services-section-point' >Insightful data reports for informed decision-making.</p>
        </div>
      </div>
      <div className="service-item">
        <AiOutlineSafetyCertificate size="5em" color="#656565" />
        <div>
          <h4 className="services-section-subheader"  >Risk Management</h4>
          <p  className='services-section-point'>Assessment and mitigation to protect your operations.</p>
        </div>
      </div>
      <div className="service-item">
        <FaLaptopFile size="5em" color="#656565" />
        <div>
          <h4  className="services-section-subheader" >Technology Integration</h4>
          <p className='services-section-point'>Efficient IT infrastructure through smart technology solutions.</p>
        </div>
      </div>
    </div>
  </TabPane>

},
  { menuItem: 
  {content: 'IAM Support',
  className: 'tab-name' 

}, render: () => <TabPane>
<div className="support-section">
  <div className="support-blurb">
  <div className="services-section-header">
    <h3>
    Our team provides ongoing support after integrating IAM solutions to maintain system stability and address any arising issues after implementation.
    </h3>
    </div>
  </div>
 <div className="support-list"> 
  <h4>Support Operations Includes:</h4>
    <ul className='support-sublist'>
      <li style={{fontSize: 15}} >  <TbArrowsRandom style={{marginRight: '10px'}}  size={40} color='#656565' /> End-to-end Support</li>
      <li style={{fontSize: 15}}> <BsTools style={{marginRight: '10px'}}  size={40} color='#656565' />      Troubleshooting and Issue Resolution</li>
      <li style={{fontSize: 15}}> <LuMonitorCheck style={{marginRight: '10px'}} size={40} color='#656565' />     Regular System Health Checks</li>
      <li style={{fontSize: 15}}> <GrDocumentPerformance style={{marginRight: '10px'}} size={40} color='#656565'  />      Performance Optimization</li>
      <li style={{fontSize: 15}}> <AiOutlineAudit style={{marginRight: '10px'}} size={50} color='#656565' />    Security Auditing and Compliance Assurance</li>
      <li style={{fontSize: 15}}> <GrDocumentUser style={{marginRight: '10px'}} size={40} color='#656565' />      User Training and Documentation</li>
    </ul>
  </div>
</div>
</TabPane> },

]

const TabExampleVerticalTabular = () => (
  <div className='servMain'>
 
  <div className="services-header-img">
  <img src={ServDec1} alt="Services Header"  className="services-header-image" style={{paddingTop: 20}}/> {/* Del is already imported as an image */}

  
</div> 


  <div id='serTabs'>
    <Tab menu={{ fluid: true, vertical: true, tabular: true,  className: 'custom-tab-menu' }} panes={panes}  />
  </div>
  
  </div>
)

export default TabExampleVerticalTabular