import React from 'react';
import Footer from './Footer';
import Careers from './Careers';
import BusinessInquiries from './BusinessInquiries';
import './comp.css';

const ContactUs = () => {
  return (

    <div style={{paddingTop: "250px"}}>
    <BusinessInquiries/>
    <Careers/>
    
    <Footer/>
    </div>
  );
};

export default ContactUs;
